/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $green $global-muted-background;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}
*::-webkit-scrollbar-track {
  background: $global-muted-background;
}
*::-webkit-scrollbar-thumb {
  background-color: $green;
  border-radius: 20px;
  border: 3px solid $green;
}
ul {
  list-style: none;
}
.uk-text li::before {
  content: "\2022";
  color: $green;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.lines {
  bottom: 0;
  display: grid;
  grid-template-columns: auto auto auto auto;
  left: 0;
  margin: 0 auto;
  max-width: 1120px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  .line {
    border-right: 1px solid rgba(72,72,72,.1);
    -webkit-transform-origin: top;
    transform-origin: top;
    height: 0;
    &:first-child {
      border-left: 1px solid rgba(72,72,72,.1);
    }
  }
}
.site-content {
  .lines {
    z-index: 2;
  }
  .uk-grid {
    z-index: 55;
    position: relative;
  }
}
.uk-h1,
.uk-h2 {
  &:before {
    background: $green;
    height: 15px;
    width: 15px;
    content: '';
    display: inline-block;
    border-radius: 50px;
  }
}
.uk-section-special {
  background: transparent linear-gradient(121deg, #00FFBA 0%, #00FFEB 100%) 0% 0% no-repeat padding-box;
  .uk-headline {
    &:before {
      background: $black;
    }
  }
}
.uk-margin-left-100 {
  @media (min-width: 960px) {
    margin-left: 100px !important;
  }
}
.uk-subtitle {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 2.8px;
  @media (min-width: $breakpoint-medium) {
    font-weight: 700;
    font-size: 28px;
  }
}
.uk-color-primary {
  color: $green !important;
}
.uk-color-black {
  color: $black;
}
.uk-justify-content-center {
  justify-content: center;
  align-items: center;
}
//scroll-to-top
.back-to-top {
  background: transparent linear-gradient(121deg, #00FFBA 0%, #00FFEB 100%) 0% 0% no-repeat padding-box;
  color: $white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 3px;
  &:hover {
    color: $black;
  }
}
.padding-top-100 {
  @media (min-width: 1200px) {
    padding-top: 20rem !important;
  }
}
.padding-top-50 {
  @media (min-width: 1200px) {
    padding-top: 15rem!important;
  }
}
.uk-grayfilter {
  -webkit-filter:  grayscale(100%) brightness(60%) contrast(10000000%);
  opacity: .3;
}
@media (min-width: $breakpoint-xlarge) {
  .padding-top-nope {
    &.uk-background-cover {
      background-position: 100% 80%;
      height: 40rem;
    }
  }
}
#unternehmen {
  img {
    border-radius: 20px;
    box-shadow: 0 3px 30px #0000001a;
  }
}
.uk-lightbox {
  z-index: 9999;
}

.transition-fade {
  transition: 0.4s;
  opacity: 1;
  position: relative;
  top: 0;
}
html.is-animating .transition-fade {
  opacity: 0;
  top: 100px;
}
img {
  border-radius: 10px;
}
.main-banner {
  img {
    border-radius: 0;
  }
}
.countdown {
  #countDown {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 30px;
    @media (min-width: $breakpoint-medium) {
      gap: 0 50px;
    }
    .days,
    .hours,
    .minutes,
    .seconds {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .timer {
      /*  background: $green;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        border: 2px solid $black;*/
        color: $black;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 21px;
        @media (min-width: $breakpoint-medium) {
          font-size: 34px;
    /*      height: 80px;
          width: 80px;*/
        }
        @media (min-width: $breakpoint-medium) {
          font-size: 44px;
        }
      }
    }
  }
  .circle-icon,
  .plus-icon,
  .wave-icon {
    position: absolute;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    @media (min-width: $breakpoint-medium) {
      width: 65px;
      height: 65px;
    }
  }
  .circle-icon {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 42 42' style='enable-background:new 0 0 42 42;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:url(%23SVGID_1_);%7D%0A%3C/style%3E%3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='-267.0215' y1='442.7018' x2='-266.0215' y2='442.7018' gradientTransform='matrix(31.7377 31.7377 31.7377 -31.7377 -5563.0806 22537.5898)'%3E%3Cstop offset='0' style='stop-color:%2300FFBA'/%3E%3Cstop offset='5.000000e-04' style='stop-color:%2300FFBA'/%3E%3Cstop offset='0.9895' style='stop-color:%2300FFEB'/%3E%3Cstop offset='1' style='stop-color:%2300FFEB'/%3E%3C/linearGradient%3E%3Cpath class='st0' d='M21,37.5V33c6.6,0,12-5.4,12-12c0-6.6-5.4-12-12-12C14.4,9,9,14.4,9,21c0,6.6,5.4,12,12,12V37.5V42 C9.4,42,0,32.6,0,21C0,9.4,9.4,0,21,0c11.6,0,21,9.4,21,21c0,11.6-9.4,21-21,21V37.5z'/%3E%3C/svg%3E%0A");

  }
  .plus-icon {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 32.1 32.1' style='enable-background:new 0 0 32.1 32.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:url(%23SVGID_1_);%7D .st1%7Bfill:url(%23SVGID_00000127038625845561993060000013975482647013744524_);%7D%0A%3C/style%3E%3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='-279.6942' y1='433.2075' x2='-278.6942' y2='433.2075' gradientTransform='matrix(46.7818 67.3102 67.3102 -46.7818 -16074.9951 39085.0078)'%3E%3Cstop offset='0' style='stop-color:%2300FFBA'/%3E%3Cstop offset='5.000000e-04' style='stop-color:%2300FFBA'/%3E%3Cstop offset='0.9895' style='stop-color:%2300FFEB'/%3E%3Cstop offset='1' style='stop-color:%2300FFEB'/%3E%3C/linearGradient%3E%3Cpolyline class='st0' points='0,13.1 32.1,13.1 32.1,19.1 0,19.1 '/%3E%3ClinearGradient id='SVGID_00000120534504509536563750000009550344862277575840_' gradientUnits='userSpaceOnUse' x1='-280.8523' y1='433.4946' x2='-279.8523' y2='433.4946' gradientTransform='matrix(67.3102 46.7818 46.7818 -67.3102 -1383.0107 42317.0156)'%3E%3Cstop offset='0' style='stop-color:%2300FFBA'/%3E%3Cstop offset='5.000000e-04' style='stop-color:%2300FFBA'/%3E%3Cstop offset='0.9895' style='stop-color:%2300FFEB'/%3E%3Cstop offset='1' style='stop-color:%2300FFEB'/%3E%3C/linearGradient%3E%3Cpolyline style='fill:url(%23SVGID_00000120534504509536563750000009550344862277575840_);' points='19.1,0 19.1,32.1 13.1,32.1 13.1,0 '/%3E%3C/svg%3E%0A");
    right: -10px;
    top: -10px;
  }
  .wave-icon {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 55.3 17.1' style='enable-background:new 0 0 55.3 17.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:url(%23SVGID_1_);%7D%0A%3C/style%3E%3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='-263.8471' y1='429.1569' x2='-262.8471' y2='429.1569' gradientTransform='matrix(55.0772 0 0 -55.0772 14532.0801 23645.3086)'%3E%3Cstop offset='0' style='stop-color:%2300FFBA'/%3E%3Cstop offset='5.000000e-04' style='stop-color:%2300FFBA'/%3E%3Cstop offset='0.9895' style='stop-color:%2300FFEB'/%3E%3Cstop offset='1' style='stop-color:%2300FFEB'/%3E%3C/linearGradient%3E%3Cpath class='st0' d='M3.7,6.8c0,0,5.2,5.4,11.4,5.3c3.2,0,6.7-1.2,10.8-5.3C30.6,1.9,35.7,0,40.2,0c9,0.1,14.9,6.7,15.1,6.8 l-3.7,3.4c0,0-5.2-5.4-11.4-5.3c-3.2,0-6.7,1.2-10.8,5.3c-4.7,4.9-9.8,6.8-14.3,6.8C6.1,17,0.2,10.4,0,10.3L3.7,6.8L3.7,6.8z'/%3E%3C/svg%3E%0A");
    top: -190px;
    left: 37%;
    width: 80px;
    @media (min-width: $breakpoint-medium) {
      width: 115px;
    }
  }
}
/* Curved Arrow */
.content {
  position: relative;
  display: none;
  @media (min-width: 1390px) {
   display: block;
  }
  .arrow {
    position: absolute;
    left: -80%;
    top: -277px;
    margin: 0 auto;
    width: 100px;
    .curve {
      border: 2px solid $green;
      border-color: transparent transparent transparent $green;
      height: 360px;
      width: 1200px;
      border-radius: 230px 0 0 150px;
    }
    .point {
      position: absolute;
      left: 40px;
      top: 315px;
      &:before,
      &:after {
        border: 1px solid $green;
        height: 25px;
        content: "";
        position: absolute;
      }
      &:before {
        top: -11px;
        left: -11px;
        transform:rotate(-74deg);
        -webkit-transform:rotate(-74deg);
        -moz-transform:rotate(-74deg);
        -ms-transform: rotate(-74deg);
      }
      &:after {
        top: -20px;
        left: 5px;
        transform:rotate(12deg);
        -webkit-transform: rotate(12deg);
        -moz-transform:rotate(12deg);
        -ms-transform: rotate(12deg);
      }
    }
  }
}
.banner-box-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 875px;
  max-width: 95%;
  position: relative;
  bottom: -110px;
  left: 50%;
  padding: 20px 20px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 8;
  text-align: center;
  a {
      border: solid 3px transparent;
      background-image: linear-gradient(rgba(95,196,225,0),rgba(217,50,138,0)),linear-gradient(101deg,#5fc4e1,#604696,#82368b,#d9328a);
      -webkit-animation: anime 16s linear infinite;
      background-size: 600%;
      animation: anime 16s linear infinite;
      background-origin: border-box;
      background-clip: content-box,border-box;
      color: white;
      transition: 0.7s;
      border-radius: 5px;
      box-shadow: none;
      padding: 0 1rem;
      font-size: 14px;
      top: 10px;
      &:hover {
        color: #000 !important;
        box-shadow: 2px 1000px 1px #fff inset;
        text-decoration: none;
      }
    }
  .uk-subtitle {
    color: white;
    font-size: 14px;
    &:before {
      content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 63.9 63.9' style='enable-background:new 0 0 63.9 63.9;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M31.9,0C14.3,0,0,14.4,0,32s14.4,31.9,32,31.9c17.6,0,31.9-14.3,31.9-31.9C63.9,14.3,49.6,0,31.9,0z M49.9,31.2 c0,8.2-5.5,15.3-13.5,17.4l0.1,6.8H27l-0.1-6.9c-7.7-2.2-13-9.3-13-17.3V14.1h9.5v17.1c0,4.7,3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5V14.1 h9.5V31.2z'/%3E%3C/svg%3E%0A");
      display: inline-block;
      width: 14px;
      padding-right: 5px;
      vertical-align: middle;
    }
  }
  h3 {
    color: white;
    font-size: 1rem;
  }
}
@media (min-width: 960px) {
  .banner-box-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    bottom: -45px;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px 45px;
    position: absolute;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    max-width: 960px;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    a {
      top: 0;
      padding: 0 1.5rem;
      font-size: 18px;
    }
    h3 {
      font-size: 1.53rem;
    }
    .uk-subtitle {
      font-size: 14px;
      &:before {
        width: 14px;
      }
    }
  }
}
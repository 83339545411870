.scene {
  margin: 0 auto;
}
.sheep {
  display:inline-block;
  position:relative;
  font-size:1em;
  $skin:#211e21;
  *{
    transition:transform 0.3s;
  }
  .top{
    position:relative;
    top:0;
    animation:bob 1s infinite;
  }
  &:hover{
    .head{
      transform:rotate(0deg);
      .eye{
        width:1.25em;
        height:1.25em;
        &:before{
          right:30%;
        }
      }
    }
    .top{
      animation-play-state:paused
    }
  }
  .head{
    display:inline-block;
    width:5em;
    height:5em;
    border-radius:100%;
    background:$skin;
    vertical-align:middle;
    position:relative;
    top:1em;
    transform:rotate(30deg);
    z-index: 2;
    &:before{
      content:'';
      display:inline-block;
      width:80%;
      height:50%;
      background:$skin;
      position:absolute;
      bottom:0;
      right:-10%;
      border-radius:50% 40%;
    }
    &:hover{
      .ear{
        &.one,&.two{transform:rotate(0deg);}
      }
    }
    .eye{
      display:inline-block;
      width:1em;
      height:1em;
      border-radius:100%;
      background: #F2F2F2;
      position:absolute;
      overflow:hidden;
      &:before{
        content:'';
        display:inline-block;
        background:black;
        width:50%;
        height:50%;
        border-radius:100%;
        position:absolute;
        right:10%;
        bottom:10%;
        transition:all 0.3s;
      }
      &.one{
        right:-2%;
        top:1.7em;
      }
      &.two{
        right:2.5em;
        top:1.7em;
      }
    }
    .ear{
      background:$skin;
      width:50%;
      height:30%;
      border-radius:100%;
      position:absolute;
      &.one{
        left:-10%;
        top:5%;
        transform:rotate(-30deg);
      }
      &.two{
        top:2%;
        right:-5%;
        transform:rotate(20deg);
      }
    }
  }
  .body{
    display:inline-block;
    width:7em;
    height:7em;
    border-radius:100%;
    background: $green;
    position:relative;
    vertical-align:middle;
    margin-right:-3em;
    z-index: 1;
  }
  .legs{
    display:inline-block;
    position:absolute;
    top:80%;
    left:10%;
    /*z-index:-1;*/
    .leg{
      display:inline-block;
      background:darken($skin,5%);
      width:0.5em;
      height:2.5em;
      margin:0.2em;
    }
  }
  &:before{
    content:'';
    display:inline-block;
    position:absolute;
    top:112%;
    width:100%;
    height:10%;
    border-radius:100%;
    background:rgba(black,0.4);
  }
}

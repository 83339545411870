.uk-slider {
  .uk-slideshow-items-wrapper {
    position: relative;

    .uk-slideshow-items {
      @media (min-width: 1350px) {
        //min-height: 1275px !important;
        -webkit-clip-path: ellipse(50% 85% at 60% 50%);
        clip-path: ellipse(50% 85% at 60% 50%);
        img {
          border-radius: 0 !important;
        }
      }
    }
  }
  .main {
    @media (min-width: 1200px) {
      flex: 3;
      order: 2;
    }
  }
  .side {
    @media (min-width: 1350px) {
      flex: 3;
      order: 1;
    }
    @media (min-width: 1200px) and (max-width: 1350px) {
      flex: 1;
      order: 1;
    }
    .uk-slideset-number {
      li {
        a {
          font-size: 12px;
          color: $black;
          &:hover {
            text-decoration: none;
          }
        }
        &.uk-active {
          a {
            color: $green;
            position: relative;
            &:after {
              content: '';
              height: 30px;
              background: $green;
              width: 1px;
              margin: 0 auto;
              display: block;
            }
          }
        }
      }
    }
    .uk-social {
      li {
        a {
          svg {
            &:hover {
              fill: $green;
            }
          }
        }
      }
    }
  }
  .uk-slideset-wrapper {
    @media (max-width: 1200px) {
      //position: absolute;
    }
  .uk-slider-block {
    .uk-subtitle {
      color: $green;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 2px;
      @media (min-width: $breakpoint-large) {
        font-size: 20px;
      }
    }
   .uk-slideset {
     padding: 0;
     margin: 10px 0 60px;
     @media (min-width: $breakpoint-medium) {
       margin: 30px 0 50px;
     }
     li {
       a {
         font-size: 30px;
         line-height: 45px;
         color: $global-muted-background;
         font-weight: 900;
         text-decoration: none;
         @media (min-width: 1350px) {
           font-size: 70px;
           line-height: 85px;
         }
         &:hover {
          text-decoration: none;
         }
       }
       &.uk-active {
         a {
           color: $black;
         }
       }
     }
   }
  }
 }
  .uk-mouse-wrapper {
    opacity: 1;
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 252;
    margin-bottom: 9px;
    @media (min-width: $breakpoint-medium) {
      margin: 0;
    }
    @media (min-width: $breakpoint-large) {
      bottom: 100px;
    }
    .uk-mouse-scroll-icon {
      width: 3px;
      height: 20px;
      padding: 6px 8px;
      --bg-opacity: 1;
      background-color: #B0B0B0;
      border-width: 2px;
      --border-opacity: 1;
      border-color: #B0B0B0;
      opacity: 0.75;
      box-sizing: content-box;
      border-radius: 1.5rem;
      .uk-mouse-scroll-icon-scroller {
        animation: 2s ease 0s infinite normal none running scroller;
        height: 4px;
        --bg-opacity: 1;
        background-color: #ffffff;
        border-radius: 9999px;
      }
    }
  }
}
@keyframes scroller {
  0% {
    opacity: 0;
  }
10% {
    opacity: 0;
  }
30% {
    transform: translateY(0px);
    opacity: 1;
  }
40% {
    transform: translateY(0px);
    opacity: 1;
  }
100% {
     transform: translateY(18px) scaleY(0.2);
     opacity: 0;
   }
}
.uk-title-banner-container {
  padding: 50px 50px 0 50px;
  @media (min-width: $breakpoint-xlarge) {
    bottom: 0;
    right: 0;
    position: absolute;
    left: 0;
    margin: 0 auto;
    max-width: 1360px;
  }
  @media (min-width: $breakpoint-large) {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 70px;
    margin: 0 auto;
    max-width: 1200px;
  }


  .uk-title-banner-block {
    background: transparent linear-gradient(121deg, #00FFBA 0%, #00FFEB 100%) 0% 0% no-repeat padding-box;
    display: inline-block;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    @media (min-width: $breakpoint-large) {
      border-radius: 10px 10px 0 0;
      width: auto;
    }
    .uk-title-banner-desc {
      .uk-headline {
        color: $black;
        margin-bottom: 0 !important;
        &:before {
          background: $black;
        }
      }
    }
    .uk-title-banner-headline {
      p {
        color: $white;
        letter-spacing: 1px;
        font-size: 21px;
      }
    }
  }
}
.contact-person {
  &:before {
    content: "";
    box-shadow: -20px -20px 0px 0px $green;
    width: calc(100% - 20px);
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    border-radius: 6px;
    left: 0;
    transition: all 0.3s linear;
  }
  &:hover {
    &:before {
      box-shadow: -20px -20px 0px 0px $black;
      height: 70%;
    }
  }
  .uk-card-media-top {
    img {
      position: relative;
      z-index: 99;
      border-radius: 6px 6px 0 0;
    }
  }
  .uk-card-body {
    background: $global-muted-background;
    padding: 20px 20px;
    border-radius: 0 0 6px 6px;
    .contact-person-name {
      font-size: 21px;
      font-weight: 700;
    }
    .contact-person-tel,
    .contact-person-mail {
      span {
        margin-right: 8px;
      }
    }
    .contact-person-mail {
      color: $black;
      &:hover {
        text-decoration: none;
        color: $green;
      }
    }
  }
}


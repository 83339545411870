.list-wrapper {
  &.uk-list-divider>:nth-child(n+2) {
    margin-top: 25px;
    padding-top: 20px;
  }
  li {
    margin: 0;
    a {
      display: grid;
      align-items: center;
      grid-template-rows: 1fr;
      grid-template-columns: 1.5fr 1fr;
      height: 100%;
      color: $green;
      padding: 0;
      &:hover {
        text-decoration: none;
        color: $black;
      }
      .desc-block {
        h3 {
          font-size: 16px;
          margin: 0;
          line-height: 1.2;
          @media (min-width: $breakpoint-small) {
            font-size: 21px;
          }
          @media (min-width: $breakpoint-large) {
            font-size: 24px;
          }
        }
        p {
          margin: 0;
          color: $black;
          font-size: 12px;
          line-height: 1.2;
          @media (min-width: $breakpoint-small) {
            font-size: 16px;
          }
          @media (min-width: $breakpoint-large) {
            font-size: 18px;
          }
        }
      }
    }
    .linkIconBlock {
      right: 0;
      position: absolute;
      width: 36px;
      height: 36px;
      border-radius: 50px;
      background: $black;
      -webkit-transition: width 0.3s, background-color 0.3s;
      transition: width 0.3s, background-color 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: $breakpoint-medium) {
        width: 40px;
        height: 40px;
      }
      @media (min-width: $breakpoint-medium) {
        width: 45px;
        height: 45px;
      }
      .uk-icon {
        right: 0;
        position: absolute;
        svg {
          width: 38px;
        }
        @media (min-width: $breakpoint-medium) {
          right: 3px;
        }
      }
      .linkHeadline {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        white-space: nowrap;
        margin: 0;
        opacity: 0;
        webkit-transition: opacity 0.01s, -webkit-transform 0.1s;
        transition: opacity 0.01s, transform 0.1s;
        font-size: 12px;
        @media (min-width: $breakpoint-medium) {
          font-size: 14px;
        }
      }
    }
    &:hover {
      .linkIconBlock {
        width: 36px;
        background-color: $global-primary-background;
        @media (min-width: $breakpoint-medium) {
          width: 180px;
        }
      }
      .linkHeadline {
        display: none;
        @media (min-width: $breakpoint-medium) {
          opacity: 1;
         -webkit-transition-delay: 0.01s;
          transition-delay: 0.01s;
          -webkit-transform: translateX(0);
          transform: translateX(0);
          display: block;
        }
      }
      @media (min-width: $breakpoint-medium) {
        .uk-icon {
          display: none;
        }
      }
    }
  }
}
.uk-section-special {
  .list-wrapper {
    &:hover {
      .linkHeadline {
        color: $white;
      }
      .linkIconBlock {
        background-color: $black;
      }
      .uk-icon {
        svg {
          color: $white;
        }
      }
    }
  }
}
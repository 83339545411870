.uk-slideshow-wrapper {
  position: relative;
  box-shadow: 0 4px 16px rgba(21, 27, 38, .8);
  width: 262px;
  height: 262px;
  border-radius: 6px;
  .uk-slideshow-content {
    .uk-slideshow-img {
      img {
        width: 20%;
      }
    }
    h3 {
      font-size: 16px;
      margin: 10px 0;
    }
    .uk-text {
      font-size: 16px;
      line-height: 1.3;
      p {
        margin: 0;
        color: white;
      }
    }
    .uk-arrow-up {
      background: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
      display: inline-block;
      height: 32px;
      margin-top: 10px;
      width: 32px;
      transition: all .4s cubic-bezier(.86,0,.07,1);
      svg {
        transform: rotate(-45deg);
        color: white;
      }
    }
  }
}
.uk-slideshow-anchor {
  &:hover {
    text-decoration: none;
    .uk-arrow-up {
      background: $green;
    }
  }
}


//community slider
.uk-box-top {
  @media (min-width: 960px) {
    padding: 0 50px;
  }
  .uk-box-wrapper {
    display: block;
    position: absolute;
    width: 200px;
    height: 200px;
    top: 0;
    left: 0;
    transform-origin: center;
    z-index: 0;
    will-change: transform;
    .uk-box-complication {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform-origin: center;
      background: $green;
      border-radius: 6px;
    }
  }
  .uk-box-img {
    margin: 25px 10px;
    width: calc(100% - 50px);
    border-radius: 6px;
  }
}
.uk-box-bottom {
  .uk-box-wrapper {
    width: 200px;
    height: 200px;
    bottom: 0;
    left: 0;
    z-index: 0;
    position: absolute;
    display: block;
    transform: rotate(0deg);
    transition: all .5s;
    .uk-box-block-bottom {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: all .6s cubic-bezier(.68,.04,.33,.98);
      transform: rotate(  0deg  ) translate(0);
      opacity: 1;
      background: $green;
      border-radius: 6px;
    }
  }
  .uk-box-img {
    margin-bottom: 35px;
    width: calc(100% - 50px);
    border-radius: 6px;

  }
}
.uk-next-button-wrapper {
  z-index: 66;
  .uk-next-button {
    text-align: center;
    padding: 0;
    border-radius: 50%;
    background: white;
    transition: all .4s;
    svg {
      color: #000;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 10px;
      vertical-align: middle;
      padding: 6px;
    }
    &:hover {
      color: #000;
      background: $green;
    }
  }
}
.uk-box-desc {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin: 15px;
  padding: 10px 0;
  @media (min-width: $breakpoint-medium) {
    padding: 30px 0;
    display: flex;
    align-items: center;
    margin: 40px 0;
    justify-content: center;
  }
  p {
    color: #000;
    font-size: 16px;
    @media (min-width: $breakpoint-medium) {
      font-size: 21px;
    }
  }
  cite {
    font-size: 12px;
    color: #000;
    display: flex;
    align-items: center;
    @media (min-width: $breakpoint-small) {
      font-size: 14px;
    }
    &:before {
      content: "";
      display: block;
      height: 1px;
      width: 30px;
      background: #000;
      margin-right: 3px;
    }
  }
}

.uk-toggle-dot-wrapper {
  .uk-toggle-dot-block {
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .uk-toggle-dot-icon {
      svg {
        width: 35px;
        height: 35px;
        background: transparent linear-gradient(121deg, #00FFBA 0%, #00FFEB 100%) 0% 0% no-repeat padding-box;
        color: $white;
        padding: 5px;
        border-radius: 50%;
        -webkit-animation: bubble-anim 2s ease-out infinite;
        animation: bubble-anim 2s ease-out infinite;
        @media (min-width: 960px) {
          width: 53px;
          height: 53px;
        }
      }
    }
    .toggle-animation-multiple {
      padding: 20px;
      position: relative;
      width: 350px;
      color: $black;
      background: transparent linear-gradient(121deg, #00FFBA 0%, #00FFEB 100%) 0% 0% no-repeat padding-box;
      margin: 20px 20px;
      text-align: left;
      @media (min-width: 960px) {
        position: absolute;
      }
      .uk-h4 {
        font-size: 26px;
        line-height: 42px;
        letter-spacing: 2.6px;
      }
      .uk-toggle-dot-desc {
        p {
          font-size: 16px;
          line-height: 30px;
          letter-spacing: 1.2px;
        }
      }
    }
  }
}
@keyframes bubble-anim {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }

  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
    transform: scaleY(0.95) scaleX(1.05); }

  48% {
    -webkit-transform: scaleY(1.1) scaleX(0.9);
    transform: scaleY(1.1) scaleX(0.9); }

  68% {
    -webkit-transform: scaleY(0.98) scaleX(1.02);
    transform: scaleY(0.98) scaleX(1.02); }

  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
    transform: scaleY(1.02) scaleX(0.98); }

  97%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1); }
}


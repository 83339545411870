.site-footer {
  .uk-navbar {
    @media (max-width: $breakpoint-small) {
      display: block;
      .footer-column {
        justify-content: center !important;
      }
    }
  }
  .uk-subnav {
    a {
      color: $black;
      transition: .6s;
      -webkit-transition: .6s;
      -moz-transition: .6s;
      -ms-transition: .6s;
      -o-transition: .6s;
      &:hover {
        color: $green;
      }
    }
  }
  .footer-column {
    p,
    .uk-subnav {
      margin-bottom: 0;
    }
  }
}
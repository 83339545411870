.uk-news-teaser-wrapper {
  .uk-text-meta {
    font-size: 12px;
    color: #a7a7a7;
    margin: 0;
    svg {
      margin-right: 5px;
    }
  }
  .uk-card-title {
    margin: 10px 0 40px;
  }
  .uk-teaser-desc {
    p {
      font-size: 15px;
      margin: 20px 0;
    }
  }
  .uk-arrow-news {
    color: $black;
    &:hover {
      opacity: 0.7;
    }
  }
  .uk-teaser-link {
    &:hover {
      text-decoration: none;
    }
    .link--secondary {
      color: white !important;
      text-decoration: none;
      display: inline-block;
      font-weight: 500;
    }
    .link__arrow {
      transform-origin: bottom left;
      margin-left: calc(1em + -10px);
      overflow: hidden;
      height: 1em;
      stroke: currentColor;
      stroke-width: 2;
      fill: transparent;
    }
    .link__arrow-inner {
      transform: translateX(-32px);
      transition: transform 200ms cubic-bezier(0.4, 0.22, 0.28, 1);
      stroke-width: 3px;
      .st1 {
        fill: none;
      }
      .st0 {
        stroke-width: 4px;
      }
    }
    &.link:hover .link__arrow-inner, &.link:focus .link__arrow-inner {
      transform: translateX(0);
      transition: transform 300ms cubic-bezier(0.4, 0.22, 0.28, 1);
      stroke: $black;
    }
    &:hover {
      line {
        stroke: $black;
      }
    }
  }
  .uk-position-center-left {
    &.uk-slidenav-previous {
      transform: translateY(-50%);
      @media (max-width: $breakpoint-small) {
        transform: translateX(25%);
      }
    }
  }
  .uk-position-center-right {
    &.uk-slidenav-next {
      transform: translate(40%, 0);
      @media (max-width: $breakpoint-small) {
        transform: translate(40%, 0);
      }
    }
  }
}

.uk-news-detail-wrapper {
  .uk-drop-cap {
      p {
        &:first-of-type {
        &:first-letter {
          float: left;
          font-size: 52px;
          line-height: 1;
          font-weight: bold;
          margin-right: 9px;
          color: $green;
        }
      }
    }
  }
}




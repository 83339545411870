.uk-offcanvas-page {
  overflow: hidden;
}

.uk-mobile-navigation {
  .uk-offcanvas {
    > .uk-offcanvas-bar {
      width: 100%;
      padding-top: 100px;
      padding-left: 0;
      padding-right: 0;
      position: fixed;
      background: $green;
      @media screen and (min-width: $breakpoint-small) {
        width: 400px;
      }
      .uk-close-wrapper {
        background: $white;
        color: $white;
        width: 100%;
        height: 50px;
        position: absolute;
        top: 0;
      }
    }
    .uk-nav-primary {
      > li {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        //background-color: #3aefaf;
        > a {
          font-size: 27px;
          font-weight: 700;
          color: white;
          padding: 10px 20px;
          &.uk-nav-small {
            font-size: 22px;
          }
          &:hover {
            color: $black;
          }
        }
      }
    }
    hr {
      border-top: 1px dashed rgba(0, 0, 0, 0.15);
    }
  }
  .uk-separator {
    background: $white;
    height: 4px;
    width: 40px;
    margin: 50px 20px;
    border-radius: 15px;
  }
}

.btn,
.uk-button {
  border-radius: 5px;
  &:hover {
    .uk-icon {
      -webkit-transform: translate(5px);
      -moz-transform: translate(5px);
      -ms-transform: translate(5px);
      -o-transform: translate(5px);
      transform: translate(5px);
    }
  }
  .uk-icon  {
    -webkit-transition: 0.70s;
    -moz-transition: 0.70s;
    -ms-transition: 0.70s;
    -o-transition: 0.70s;
  }
}
.uk-subnav-pill {
  .uk-active > a {
    border-radius: 5px;
    &:hover {
      border-radius: 5px;
    }
  }
  > * > :first-child {
    background: #f8f8f8;
    border-radius: 5px;
  }
}


/* Burger - Important styles */
#toggle {
  display: block;
  width: 28px;
  height: 30px;
  margin: 30px auto 10px;
  z-index: 99999;
  position: relative;
  span:after,
  span:before {
    content: "";
    position: absolute;
    left: 0;
    top: -9px;
  }
  span:after{
    top: 9px;
  }
  span {
    position: relative;
    display: block;
  }
  span,
  span:after,
  span:before {
    width: 100%;
    height: 5px;
    background-color: $black;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px;
  }
  .uk-menu-desc {
    bottom: 14px;
    position: absolute;
    right: 40px;
    text-transform: uppercase;
    font-size: 12px;
  }
}
.uk-toggle-effect {
  .uk-menu-desc {
    &:before {
      content: "Menü";
      font-size: larger;
      color: $black;
    }
  }
}
.uk-toggle-effect {
  &.on {
    .uk-menu-desc {
      &:before {
        content: "Schließen";
        font-size: larger;
        color: $black;
      }
    }
  }
}
/* Burger - on activation */
#toggle.on {
  span {
    background-color: transparent;
    &:before {
      transform: rotate(45deg) translate(5px, 5px);
    }
    &:after {
      transform: rotate(-45deg) translate(7px, -8px);
    }
  }
}
#toggle.on + #menu {
  opacity: 1;
  visibility: visible;
}
.overlay-navigation {
  .site-header {
    @media (min-width: 1350px) {
      position: absolute;
    }
  }
}
.site-header {
  @extend .uk-position-z-index;
  position: fixed;
  width: 100% !important;
  z-index: 9999;
  padding: 20px 0;
  @media (min-width: $breakpoint-large) {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  .uk-logo {
    svg {
      width: 150px;
      .st0 {
        fill: $green;
      }
    }
  }

  &.uk-sticky-fixed {
    //background: transparent linear-gradient(121deg, #00FFBA 0%, #00FFEB 100%) 0% 0% no-repeat padding-box;
    /*background: $green;*/
    /*box-shadow: 0 0 15px 20px $green;*/
    background: transparent linear-gradient(121deg,#00ffba 0,#00ffeb 100%) 0 0 no-repeat padding-box;
    padding: 10px 0;
    z-index: 9999;


    .uk-logo {
      svg {
        filter: brightness(0) invert(1);
        width: 130px;
      }
    }
    .uk-navbar-nav {
      a {
        &:hover {
          color: $white;
          &:after {
            background: $white;
          }
        }
      }
      .uk-active {
        a {
          color: $white;
          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            content: '.';
            color: transparent;
            background: $white;
            height: 2px;
          }
        }
      }
    }
    .uk-button {
      color: $black;
      border: 1px solid $black;
      background: none;
      &:hover {
        background: $white;
      }
    }
  }
  .uk-navbar-nav {
    li {
      padding: 0 20px;
      a {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 2.4px;
        transition: all .5s;
        position: relative;
        display: block;
        text-decoration: none;
        min-height: auto;
        padding: 0;
        &:after,
        &:before {
          transition: all .5s;
        }
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 0;
          content: '.';
          color: transparent;
          background: $green;
          height: 2px;
        }
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
      &.uk-active {
        a {
          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            content: '.';
            color: transparent;
            background: $green;
            height: 2px;
          }
        }
      }
    }
  }
}



form {
  z-index: 99;
  position: relative;
  @extend .uk-text-small;

  fieldset {
    margin: 0;
    padding: 0 1rem;
    border: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    @media (min-width: $breakpoint-small) {
      float: left;
      @extend .uk-width-1-2\@s;
    }
  }

  .form-group {
    @extend .uk-margin-bottom;
  }

  .form-inline {

    > select {
      @extend .uk-form-width-xsmall;

      &:first-child {
        margin-right: .25rem;

        @media (min-width: $breakpoint-small) {
          margin-right: .5rem;
        }
      }

      &:nth-child(2) {
        margin: 0 .25rem;

        @media (min-width: $breakpoint-small) {
          margin: 0 .5rem;
        }
      }

      &:last-child {
        @extend .uk-form-width-small;
        margin-left: .25rem;

        @media (max-width: $breakpoint-xsmall-max) {
          width: 116px;
        }

        @media (min-width: $breakpoint-small) {
          margin-left: .5rem;
        }
      }
    }
  }

  input[type="text"],
  input[type="email"] {
    @extend .uk-input;
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    &:hover,
    &:active {
      border: 1px solid $green;
    }
  }

  textarea {
    @extend .uk-textarea;
    border: 1px solid #b9b9b9;
    padding-top: 20px;
    border-radius: 6px;
    &:hover,
    &:active {
      border: 1px solid $green;
    }
  }

  input[type="checkbox"] {
    @extend .uk-checkbox;
    border: none;
  }

  select {
    @extend .uk-select;
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    &:hover,
    &:active {
      border: 1px solid $green;
    }
  }

  button {
    float: right;
    @extend .uk-button;
    @extend .uk-button-primary;
  }
  .btn {
    letter-spacing: 2.4px;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    &:hover,
    &:active {
      background: #2cffba;
      color: $white;
    }
    &.btn-default {
      padding: 5px;
      position: relative;
      top: 10px;
      text-decoration: none;
    }
  }
  .checkbox {
    line-height: $text-small-line-height;

    a {
      text-decoration: underline;
    }
  }
  .input-group-addon {
    img {
      padding: 20px 0;
    }
  }
}
.alert {
  &.alert-danger {
    background: #fef4f6;
    color: #f0506e;
    padding: 10px 0 5px 0;
    margin: 0 0 20px 0;
    border-radius: 6px;
  }
}
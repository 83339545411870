.gallery-block {
  width: 270px;
  height: 494px;
  background-color: $dark-grey;
  margin: auto;
  position: relative;
  img {
    max-width: 100%;
  }
  .left-top {
    position: absolute;
    right: 54%;
    top: 100px;
  }
  .left-bottom {
    position: absolute;
    right: 54%;
    top: 280px;
  }
  .right-top {
    position: absolute;
    left: 50%;
    top: 30px;
  }
  .right-bottom {
    position: absolute;
    left: 50%;
    top: 200px;
  }
}

$breakpoint-small: 640px;
$breakpoint-medium: 960px;
$breakpoint-large: 1200px;
$breakpoint-xlarge: 1600px;

//specialbreakpoints
@media (min-width: 960px) {
  .uk-width-70-m {
    width: 70%;
  }
}

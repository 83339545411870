.liquid-container {
  .wave {
    transform: rotate(180deg);
    .wave-top {
      position: relative;
      width: 100%;
      path {
        fill: $green;
      }
    }
  }
  .wave-block {
    position: relative;
    top: -30px;
    @media (min-width: $breakpoint-medium) {
      top: -80px;
    }
    @media (min-width: $breakpoint-large) {
      top: -150px;
    }
    .wave-bottom {
      background: $green;
      display: flex;
      align-items: center;
      position: relative;
      div {
        display: flex;
        .word {
          font-size: 20px;
          font-weight: 700;
          color: $white;
          position: relative;
          display: flex;
          align-items: center;
          @media (min-width: $breakpoint-medium) {
            font-size: 50px;
          }
          @media (min-width: $breakpoint-large) {
            font-size: 60px;
          }
          &:after {
            content: '';
            margin: 0 40px;
            width: 40px;
            height: 2px;
            background: $white;
            display: inline-block;
            @media (min-width: $breakpoint-medium) {
              margin: 0 80px;
              width: 100px;
            }
          }
        }
      }
      .position-second {
        .word {
          -webkit-text-stroke: 1px $white;
          text-stroke: 1px $white;
          color: transparent;
          @media (min-width: $breakpoint-medium) {
            -webkit-text-stroke: 2px $white;
            text-stroke: 2px $white;
          }
        }
      }
    }
  }
}

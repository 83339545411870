.uk-roadmap-slider {
  .uk-slider-panel-wrapper {
    @media screen and (min-width: $breakpoint-large) {
      padding-right: 50px;
    }
    .uk-date {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 2.8px;
      color: $black;
      @media (min-width: 960px) {
        font-size: 28px;
      }
    }
    .uk-desc {
      color: $white;
      font-size: 21px;
      line-height: 30px;
      font-weight: 900;
      @media (min-width: $breakpoint-small) {
        font-size: 26px;
        line-height: 34px;
      }
      @media (min-width: $breakpoint-medium) {
        font-size: 31px;
        line-height: 40px;
      }
      @media (min-width: $breakpoint-large) {
        font-size: 41px;
        line-height: 50px;
      }
      @media (min-width: $breakpoint-medium) {
        &:before {
          height: 100%;
          width: 3px;
          background: $black;
          content: '';
          position: absolute;
          left: 50px;
        }
        p {
          margin: 0 0 0 100px;
        }
      }
    }
  }
}

.uk-arrow {
  color: $white;
  z-index: 555;
  svg {
    width: 30px;
    @media (max-width: 1200px) {
      border-radius: 50%;
      background: $green;
      width: 40px;
      height: 40px;
      padding: 10px;
      color: $white;
      border: 1px solid $white;
    }
  }
  &:hover,
  &:focus {
    color: $black;
  }
  &.uk-position-center-right {
    @media (max-width: $breakpoint-large) {
      transform: translateX(40%);
    }
    @media (max-width: $breakpoint-small) {
      transform: translateX(15%);
    }
  }

  &.uk-position-center-left {
    @media (max-width: $breakpoint-large) {
      transform: translateX(-45%);
  }
    @media (max-width: $breakpoint-small) {
      transform: translateX(-25%);
    }
  }
  &.uk-position-center-left-out {
    @media (min-width: $breakpoint-large) {
      transform: translateX(50%);
    }
  }
  &.uk-position-center-right-out {
    @media (min-width: $breakpoint-large) {
      transform: translateX(-50%);
    }
  }
}
.uk-accordion-title {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2.8px;
    line-height: 21px;
    padding: 20px 0;
    @media (min-width: 960px) {
        font-size: 18px;
        line-height: 24px;
    }
    @media (min-width: 1200px) {
        font-size: 28px;
        line-height: 42px;
    }
    &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32.668' viewBox='0 0 32 32.668'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%232cffba;stroke-width:5px;%7D%3C/style%3E%3C/defs%3E%3Cline class='a' x2='32' transform='translate(0 16.334)'/%3E%3Cline class='a' x2='32.668' transform='translate(16) rotate(90)'/%3E%3C/svg%3E");
        content: "";
        width: 20px;
        height: 20px;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        padding: 0 15px;
        float: left;
        @media (min-width: 1200px) {
            width: 30px;
            height: 30px;
            background-size: 30px;
            padding: 5px 15px;
        }
    }
}
.uk-open > .uk-accordion-title::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='5px' viewBox='0 0 32 5' style='enable-background:new 0 0 32 5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-width:5;%7D%0A%3C/style%3E%3Cg transform='translate(-1502.58 -2933.334)'%3E%3Cline class='st0' x1='1502.6' y1='2935.8' x2='1534.6' y2='2935.8'/%3E%3C/g%3E%3C/svg%3E%0A");
}
.uk-accordion-content {
    margin: 0 0 0 20px;
    padding-bottom: 10px;
    border-radius: 6px;
    @media (min-width: 1200px) {
        margin: 0 0 0 30px;
    }
    p {
        font-size: 16px;
    }
    .uk-button {
        font-size: 16px;
        padding: 0;
        color: $green;
        font-weight: 500;
    }
    .uk-progress {
        top: -7px;
        border-radius: 15px;
    }
}
.uk-accordion {
    @media (max-width: 1200px) {
        max-width: 100%;
    }
    @media (min-width: 1200px) {
        width: 960px;
        margin: 0 auto;
    }
    li {
        padding: 0;
        position: relative;
        &:nth-child(n+2) {
            margin: 0;
        }
        &:before {
            content: '';
            position: absolute;
            background-color: rgba(44,255,186,.6)!important;
            z-index: -1;
            opacity: 0;
            transform: scale(1.1);
            width: 100%;
            height: 100%;
            top: 0;
            border-radius: 6px;
            left: 0;
            transition: opacity 80ms linear,transform 80ms ease-in;
        }
        &:hover {
            opacity: 1;
            transform: scale(1);
            transition: opacity 0.2s linear,transform 0.2s ease-out;
        }
        &:hover {
            &:before {
                opacity: 1;
                transform: scale(1);
                transition: opacity 0.2s linear,transform 0.2s ease-out;
            }
        }
        .uk-accordion-content {
            li {
                &:before {
                    opacity: 0;
                    background: none;
                }
            }
        }
    }
    .uk-accordion-title {
        @media (max-width: 1200px) {
            display: flex;
            flex-direction: row;
            &:after {
                order: -1;
            }
        }
        &:hover {
            &:after {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='5px' viewBox='0 0 32 5' style='enable-background:new 0 0 32 5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-width:5;%7D%0A%3C/style%3E%3Cg transform='translate(-1502.58 -2933.334)'%3E%3Cline class='st0' x1='1502.6' y1='2935.8' x2='1534.6' y2='2935.8'/%3E%3C/g%3E%3C/svg%3E%0A");
            }
        }
    }
}

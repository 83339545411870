@mixin font-face($family,$src,$style: normal,$weight: normal) {
    @font-face {
        font-family: $family;
        font-display: swap;
        font-style: $style;
        font-weight: $weight;
        src: url('#{$src}.eot');
        src: url('#{$src}.eot?#iefix') format('embedded-opentype'),
        url('#{$src}.woff2') format('woff2'),
        url('#{$src}.woff') format('woff'),
        url('#{$src}.ttf') format('truetype'),
        url('#{$src}.svg##{$family}') format('svg');
    }
}

@include font-face('Inter',  '../fonts/inter/inter-v3-latin-200',  normal, 200);
@include font-face('Inter',  '../fonts/inter/inter-v3-latin-regular',  normal, 400);
@include font-face('Inter',  '../fonts/inter/inter-v3-latin-700', normal, 700);
@include font-face('Inter',  '../fonts/inter/inter-v3-latin-800', normal, 800);
@include font-face('Inter',  '../fonts/inter/inter-v3-latin-900', normal, 900);


html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6 {
    margin-top: 0;
}
h1, + .uk-h1,
h2, + .uk-h2 {
    font-weight: 900 !important;
}
h3, + .uk-h3 {
    font-weight: 900 !important;
    letter-spacing: 2.8px;
}
h4, + .uk-h4 {
    font-weight: 700 !important;
    letter-spacing: 2.8px;
}

.uk-button {
  letter-spacing: 2.4px;
  font-weight: 700;
  font-size: 16px;
  &-primary {
    background: transparent linear-gradient(121deg, #00FFBA 0%, #00FFEB 100%) 0% 0% no-repeat padding-box;
    color: $black;
    border-radius: 6px;
    text-transform: uppercase;
    &:hover {
      background: $green;
      color: $white;
    }
  }
  &-secondary {
    background: $white;
    border: 1px solid $green;
    color: $black;
    &:hover {
      border: 1px solid $green;
      background: $green;
    }
  }
}

